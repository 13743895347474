import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  connect() {
    $(this.element).datepicker({
      autoclose: true,
      language: 'zh-CN',
      weekStart: 1,
      todayBtn: true,
      todayHighlight: true,
      format: 'yyyy-mm-dd'
    });
  }
}
