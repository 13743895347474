import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initRichTextArea() {
    TinyMCERails.configuration.default = {
      convert_urls: true,
      relative_urls: false,
      remove_script_host: false,
      selector: "textarea.tinymce",
      cache_suffix: "?v=6.4.2",
      menubar: false,
      min_height: 100,
      toolbar: ["styles | bold italic | forecolor backcolor | numlist bullist | table | link | code | undo redo"],
      plugins: "link,table,lists,code,autoresize"
    };
    TinyMCERails.initialize('default', {

    });
    document.addEventListener('focusin', (e) => {
      if (e.target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
        e.stopImmediatePropagation();
      }
    });
  }

  initDateFields() {
    $('input.date').datepicker({
      autoclose: true,
      language: 'zh-CN',
      weekStart: 1,
      todayBtn: true,
      todayHighlight: true,
      format: 'yyyy-mm-dd'
    });
  }

  showModal(elementId) {
    let modal = coreui.Modal.getOrCreateInstance(elementId);
    modal.show();
  }

  hideModal(elementId) {
    if($(elementId).length > 0) {
      let modal = coreui.Modal.getOrCreateInstance(elementId);
      modal.hide();
      modal.dispose();
    }
  }

  setModalTitle(elementId, text) {
    $(elementId + " .modal-title").html(text)
  }

  setModalBody(elementId, text) {
    $(elementId + " .modal-body").html(text)
  }

  initPopover() {
    const popoverTriggerList = document.querySelectorAll('[data-coreui-toggle="popover"]')
    const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new coreui.Popover(popoverTriggerEl))
  }
}
