import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = [ "quantity", "amount", "overdueQuantity", "label" ]
  static values = {
      threshold: Boolean
  }

  connect() {
    let url = $(this.element).data("url")
    let self = this
    $.get(url, function(data) {
        self.quantityTarget.innerHTML = data.quantity
        if (data.amount) {
          self.amountTarget.innerHTML = data.amount
          $(self.amountTarget).show();
        }
        if (data.overdue_quantity) {
          self.overdueQuantityTarget.innerHTML = data.overdue_quantity
          $(self.overdueQuantityTarget).parent().show();
        }
        if (self.thresholdValue && data.quantity && data.quantity > 0) {
          $(self.quantityTarget).addClass("text-warning")
          $(self.labelTarget).addClass("text-warning")
        }
    })
  }
}
