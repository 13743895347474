import jQuery from "jquery";
window.jQuery = jQuery;
window.$ = jQuery;

import Rails from '@rails/ujs';
Rails.start();

require("@rails/activestorage").start()
require("channels")

import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = false

import "devbridge-autocomplete/dist/jquery.autocomplete"
import "bootstrap/dist/js/bootstrap.bundle"
import "bootstrap-datepicker/dist/js/bootstrap-datepicker"
import "bootstrap-datepicker/js/locales/bootstrap-datepicker.zh-CN"
import "bootstrap-table/dist/bootstrap-table"
import "bootstrap-table/dist/bootstrap-table-locale-all"
import moment from "moment/moment"
import PerfectScrollbar from "perfect-scrollbar/dist/perfect-scrollbar"
import "daterangepicker"
import Sortable from "sortablejs"
import "chartkick/chart.js"
import "./pagy.js.erb"
import "./direct_uploads.js.erb"
import ClipboardJS from "clipboard/dist/clipboard"
import NProgress from "nprogress/nprogress"
import Trix from "trix"

import * as coreui from '@coreui/coreui'
window.coreui = coreui;

import 'simplebar'
import ResizeObserver from 'resize-observer-polyfill'
window.ResizeObserver = ResizeObserver;

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

window.Stimulus = Application.start()
const context = require.context("./controllers", true, /\.js$/)
Stimulus.load(definitionsFromContext(context))

$(document).ready(function(){
  const popoverTriggerList = document.querySelectorAll('[data-coreui-toggle="popover"]');
  const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new coreui.Popover(popoverTriggerEl));

  const toastElList = document.querySelectorAll('.toast')
  const toastList = [...toastElList].map(toastEl => new coreui.Toast(toastEl, { autohide: false }))
  $(toastList).each(function(index, toast) {
    toast.show();
  })

  var start = moment().subtract(29, 'days');
  var end = moment();
  function cb(start, end) {
    $('#reportrange span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
  }
  $('input.dates').daterangepicker({
    language: 'zh-CN',
    autoUpdateInput: false,
    opens: 'left',
    locale: {
      format: 'YYYY-MM-DD',
      "applyLabel": "应用",
      "cancelLabel": "取消",
      "fromLabel": "开始",
      "toLabel": "截止",
      customRangeLabel: '选择日期范围',
      "daysOfWeek": [
        "Su",
        "Mo",
        "Tu",
        "We",
        "Th",
        "Fr",
        "Sa"
      ],
      "monthNames": [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ],
    },
    ranges: {
      '今天': [moment(), moment()],
      '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      '最近7天': [moment().subtract(6, 'days'), moment()],
      '最近30天': [moment().subtract(29, 'days'), moment()],
      '本月': [moment().startOf('month'), moment().endOf('month')],
      '上月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    }
  }, cb);
  $('input.dates').on('apply.daterangepicker', function(ev, picker) {
    $(this).val(picker.startDate.format('YYYY-MM-DD') + ' - ' + picker.endDate.format('YYYY-MM-DD'));
  });

  $('input.dates').on('cancel.daterangepicker', function(ev, picker) {
    $(this).val('');
  });

  $('table.bootstrap-table').bootstrapTable({
    classes: 'table-no-bordered',
    locale: 'zh-CN',
    pagination: true,
    pageSize: 20,
    pageList: [20, 50, 100]
  });

  if($("#asize").length > 0) {
    new PerfectScrollbar('#aside .tab-pane', {
      wheelSpeed: 2,
      wheelPropagation: true,
      minScrollbarLength: 20
    });
  }

  $(".sortable").each(function(index, el) {
    new Sortable(el, {
      handle: '.sortable-handle',
      draggable: '.sortable-item',
      onChange: function(e) {
        $.each($(e.target).find('input.sortable-position'), function(index, item) {
          $(item).val(index + 1);
        });
      }
    });
  });

  var clipboard = new ClipboardJS('.copy-btn');
  clipboard.on("success", function(e) {
    coreui.Tooltip.getOrCreateInstance(e.trigger).show();
  });

  $(document).on("ajax:beforeSend", function(xhr, settings) {
    NProgress.start();
    NProgress.set(0.5)
  });

  $(document).on("ajax:success", function(xhr, status) {
    NProgress.done();
  });

  $(document).on("ajax:error", function(xhr, status) {
    NProgress.done();
  });
});
