import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  showFormModal(event) {
    let [response, status, xhr] = event.detail;
    super.setModalBody("#order-line-item-form-modal", xhr.response);
    super.setModalTitle("#order-line-item-form-modal", "编辑订单行");
    super.showModal("#order-line-item-form-modal");
  }
}
