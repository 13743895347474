import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  showStatementLineItemsModal(event) {
    let [response, status, xhr] = event.detail;
    $('#statement-line-items-modal').remove();
    $('body').append(xhr.response);

    $('#statement-line-items-modal table').bootstrapTable({
      classes: 'table-no-bordered',
      pagination: true,
      pageSize: 20,
      pageList: [20, 50, 100],
      locale: 'zh-CN'
    });

    super.showModal('#statement-line-items-modal');
  }
}
