import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  onPostSuccess(event) {
    let [response, status, xhr] = event.detail
    super.hideModal('#pack-form-modal');
    console.log("post success")

    let id = $.parseHTML(xhr.response)[0].id
    if($("#" + id).length > 0) {
      $("#" + id).replaceWith(xhr.response)
    } else {
      $('#packs').append(xhr.response)
    }
  }

  onPostError(event) {
    let [response, status, xhr] = event.detail
    $('#pack-form-modal .modal-body').html(xhr.response)
  }
}
