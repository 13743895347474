import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  toggleAll(event) {
    var checked = $(event.target).is(":checked");
    var purchaseQuotationId = $(event.target).data("purchase-quotation-id");
    $(".purchase-quotation-" + purchaseQuotationId).each(function(index, item){
        $(item).prop("checked", checked);
    });

    $("[data-purchase-quotation-id!=" + purchaseQuotationId + "]").each(function(index, item) {
      if(checked && $(item).prop("checked")) {
          $(item).prop("checked", false);
      }
    });
  }

  toggleOthers(event) {
    var checked = $(event.target).is(":checked");
    var enquiryLineItemId = $(event.target).data("enquiry-line-item-id");
    $("[data-enquiry-line-item-id=" + enquiryLineItemId + "]").each(function(index, item) {
        if(item != event.target && checked) {
            $(item).prop("checked", false)
        }
    });
  }
}
