import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ['customer']

  showExpensesModal(event) {
    let [response, status, xhr] = event.detail;

    if($("#expenses-modal").length > 0) {
      $("#expenses-modal").remove()
    }
    $('body').append(xhr.response);

    $('#expenses-modal table').bootstrapTable({
      classes: 'table-no-bordered',
      pagination: true,
      pageSize: 20,
      pageList: [20, 50, 100],
      locale: 'zh-CN'
    });

    super.showModal('#expenses-modal');
  }
}
