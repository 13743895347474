import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  showFormModal(event) {
    let [response, status, xhr] = event.detail
    super.setModalBody("#task-form-modal", xhr.response);
    super.setModalTitle("#task-form-modal", "新建任务");
    super.showModal("#task-form-modal");
    super.initDateFields()
  }
}
