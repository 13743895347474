import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  showFormModal(event) {
    let [response, status, xhr] = event.detail
    $("#task-form-modal .modal-body").html(xhr.response)
    $("#task-form-modal .modal-title").html("编辑任务")
    super.showModal("#task-form-modal");
    super.initDateFields();
  }

  remove(event) {
    let [response, status, xhr] = event.detail
    $(this.element).remove()
  }
}
