import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  submit(event) {
    let [response, status, xhr] = event.detail;
    $('.line-items tr.no-records-found').remove();
    $('.line-items').append(xhr.response);

    $.each($('.line-items').find('.sortable-item input.sortable-position'), function(index, item) {
      $(item).val(index + 1);
    });

    super.hideModal('#products-modal');
    super.initDateFields();
    super.initPopover();
  }

  selectAll(event) {
    if($(event.target).is(":checked")) {
      $(this.element).find("input[type=checkbox]").prop("checked", true);
    } else {
      $(this.element).find("input[type=checkbox]").prop("checked", false);
    }
  }
}
