import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  submit(event) {
    $(".loader").removeClass("is-active");

    let [response, status, xhr] = event.detail;
    $('.line-items tr.no-records-found').remove();
    $('.line-items').append(xhr.response);

    super.hideModal('#invoice-line-items-modal');
    super.initDateFields();
    super.initPopover();
  }
}
