import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  showProductsModal(event) {
    let [response, status, xhr] = event.detail;

    $("#products-modal").remove()
    $("body").append(xhr.response);

    $("#products-modal table").bootstrapTable({
      classes: "table-no-bordered",
      pagination: true,
      pageSize: 20,
      pageList: [20, 50, 100],
      locale: "zh-CN",
      onLoadSuccess: function(data) {
        $.each(data.rows, function(index, value) {
          var cell = $("input[value=" + value.id + "]")[0];
          $(cell).attr("name", "products[" + value.id + "][id]")
        });
      }
    });

    super.showModal('#products-modal');
  }
}
