import ApplicationController from "./application_controller"

export default class extends ApplicationController {
   connect() {
     let table = this.element
     $(table).find("thead input[type='checkbox']").on("click", function(event){
       if($(event.target).is(":checked")) {
         $(table).find("tbody input[type='checkbox']").prop("checked", true);
       } else {
         $(table).find("tbody input[type='checkbox']").prop("checked", false);
       }
     })
  }
}
