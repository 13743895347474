import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  submit(event) {
    let [response, status, xhr] = event.detail;
    $('.line-items tr.no-records-found').remove();
    $('.line-items').append(xhr.response);

    super.hideModal('#statement-line-items-modal');
    super.initDateFields();
    super.initPopover();
  }

  get statementFormController() {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === 'statement-form';
    });
  }
}
