import ApplicationController from "./application_controller"
import Dinero from "dinero.js"

export default class extends ApplicationController {
  static targets = ["unitPrice", "quantity", "amount", "currency"]

  initialize() {
    if(this.hasUnitPriceTarget) {
      this.unitPrice = this.unitPriceTarget.value
    }
    if(this.hasQuantityTarget) {
      this.quantity = this.quantityTarget.value
    }
  }

  copy(event) {
    let [response, status, xhr] = event.detail;
    $(this.element).after(xhr.response);
  }

  calculate(event) {
    if(this.hasUnitPriceTarget && this.hasQuantityTarget) {
      this.unitPrice = this.unitPriceTarget.value
      this.quantity = this.quantityTarget.value

      this.amountTarget.innerHTML = this.amount.toFormat('0.000')
      if(this.statementFormController) {
        this.statementFormController.calculate()
      }
    }
  }

  get statementFormController() {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === 'statement-form';
    });
  }

  get unitPrice() {
    var value = parseFloat(this.data.get("unitPrice")) * 1000 || 0;
    return Dinero({ amount: parseInt(value), precision: 3 });
  }

  set unitPrice(value) {
    this.data.set("unitPrice", value);
  }

  get quantity() {
    return parseFloat(this.data.get("quantity"));
  }

  set quantity(value) {
    this.data.set("quantity", value);
  }

  get amount() {
    return this.unitPrice.multiply(this.quantity) || 0
  }

  remove(event) {
    $(event.target).closest('tr').hide()
    $(event.target).closest('tr').find('input.destroy-field').val('1');
  }
}
