import ApplicationController from "./application_controller"
import NProgress from "nprogress/nprogress"

export default class extends ApplicationController {
  connect() {
    let input = $(this.element).find(".autocomplete-input")[0]
    let url = $(input).data("url");
    $(input).autocomplete({
      autoSelectFirst: true,
      showNoSuggestionNotice: true,
      noSuggestionNotice: 'No Result',
      minChars: 1,
      serviceUrl: url,
      onSearchStart: function(params) {
        NProgress.start();
        NProgress.set(0.5);

      },
      onSearchComplete: function(query, suggestions) {
        NProgress.done();
      },
      onSearchError: function(query, jqXHR, textStatus, errorThrown) {
        NProgress.done();
      }
    });
  }
}
