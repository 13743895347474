import ApplicationController from "./application_controller"
import Tribute from "tributejs"
import Trix from "trix"

export default class extends ApplicationController {
  static targets = [ "field" ]

  connect() {
    this.editor = this.fieldTarget.editor
    this.initializeTribute()
  }

  initializeTribute() {
      this.tribute = new Tribute({
      collection: [
         {
           trigger: "@",
           allowSpaces: true,
           lookup: "name",
           menuShowMinLength: 1,
           menuItemLength: 10,
           values: this.fetchUsers
         }
       ]
    });

    this.tribute.attach(this.fieldTarget)

    this.fieldTarget.addEventListener("tribute-replaced", this.replaced)
    this.tribute.range.pasteHtml = this._pasteHtml.bind(this)


    // More code to come
  }

  fetchUsers(text, callback) {
   fetch("/erp/system/users.json?query=" + text)
     .then(response => response.json())
     .then(users => callback(users))
     .catch(error => callback([]))
  }

  replaced(event) {
    let mention = event.detail.item.original
    let attachment = new Trix.Attachment({
      sgid: mention.sgid,
      content: mention.content
    })
    this.editor.insertAttachment(attachment)
    this.editor.insertString(" ")
  }

  _pasteHtml(html, startPos, endPos) {
    let range = this.editor.getSelectedRange()
    let position = range[0]
    let length = endPos - startPos

    this.editor.setSelectedRange([position - length, position])
    this.editor.deleteInDirection("backward")
  }

  disconnect() {
    this.tribute.detach(this.fieldTarget)
  }
}
