import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  showFormModal(event) {
    let [response, status, xhr] = event.detail;
    super.setModalBody("#pack-form-modal", xhr.response);
    super.setModalTitle("#pack-form-modal", "新建包装");
    super.showModal('#pack-form-modal');
  }

  showListModal(event) {
    let [response, status, xhr] = event.detail;
    if($("#packs-modal").length > 0) {
      $("#packs-modal").replaceWith(xhr.response);
    } else {
      $("body").append(xhr.response);
    }

    $("#packs-modal table").bootstrapTable({
      classes: "table-no-bordered",
      pagination: true,
      pageSize: 20,
      pageList: [20, 50, 100],
      locale: "zh-CN"
    });

    super.showModal('#packs-modal');
  }
}
