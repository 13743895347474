import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  showDeliveryLineItemsModal(event) {
    let [response, status, xhr] = event.detail;
    $('#delivery-line-items-modal').remove();
    $('body').append(xhr.response);

    $('#delivery-line-items-modal table').bootstrapTable({
      classes: 'table-no-bordered',
      pagination: true,
      pageSize: 20,
      pageList: [20, 50, 100],
      locale: 'zh-CN'
    });

    super.showModal('#delivery-line-items-modal');
  }

  toggleDeclareableFields() {
    if($("#shipping_declareable").prop("checked")) {
      $("#shipping_customs_declaration_number").attr("disabled", false)
      $("#shipping_tax_refund_submitted").attr("disabled", false)
      $('#customs_power_of_attorney_link').show()
      $('#customs_declaration_link').show()
    } else {
      $("#shipping_customs_declaration_number").attr("disabled", true)
      $("#shipping_tax_refund_submitted").attr("disabled", true)
      $('#customs_power_of_attorney_link').hide()
      $('#customs_declaration_link').hide()
    }
  }

  toggleTransitRequiredFields() {
    if($("#shipping_transit_required").prop("checked")) {
      $("#shipping_transit_tracking_number").attr("disabled", false)
      $("#shipping_transit_forwarder_name").attr("disabled", false)
      $("#shipping_transit_port_name").attr("disabled", false)
    } else {
      $("#shipping_transit_tracking_number").attr("disabled", true)
      $("#shipping_transit_forwarder_name").attr("disabled", true)
      $("#shipping_transit_port_name").attr("disabled", true)
    }
  }

  toggleBookingRequiredFields() {
    if($("#shipping_booking_required").prop("checked")) {
      $("#shipping_warehouse_entry_number").attr("disabled", false)
    } else {
      $("#shipping_warehouse_entry_number").attr("disabled", true)
    }
  }
}
