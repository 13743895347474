import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  selectAll(event) {
    if($(event.target).is(":checked")) {
      $(this.element).find("input[type=checkbox]").prop("checked", true);
    } else {
      $(this.element).find("input[type=checkbox]").prop("checked", false);
    }
  }
}
