import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  onPostSuccess(event) {
    let [response, status, xhr] = event.detail
    super.hideModal('#order-line-item-form-modal');

    let id = $.parseHTML(xhr.response)[0].id
    if($("#" + id).length > 0) {
      $("#" + id).replaceWith(xhr.response)
    } else {
      $('#order-line-items').append(xhr.response)
    }
  }

  onPostError(event) {
    let [response, status, xhr] = event.detail
    $('#order-line-item-form-modal .modal-body').html(xhr.response)
  }
}
