import ApplicationController from "./application_controller"
import NProgress from "nprogress/nprogress"

export default class extends ApplicationController {
  connect() {
    let input = $(this.element).find(".dropdown-input")[0]
    let url = $(input).data("url");
    let spinner = $(this.element).find("span.spinner-border")
    let arrow = $(this.element).find("span.fa.fa-caret-down")
    let close = $(this.element).find("span.fa.fa-close")
    $(input).autocomplete({
      autoSelectFirst: true,
      minChars: 0,
      serviceUrl: url,
      showNoSuggestionNotice: true,
      noSuggestionNotice: "<div class='autocomplete-suggestion'>没有找到任何结果</div>",
      onSelect: function(suggestion) {
        $($(input).data("dom-id")).val(suggestion.data);
        $($(input).data("dom-id")).trigger("change");
      }
    });

    $(close).on("click", function(event) {
      $(input).val('')
    });

    $(arrow).on("click", function(event) {
      $(input).trigger("focus");
    });
  }
}
