import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  showDeliveryLineItemsModal(event) {
    let [response, status, xhr] = event.detail;
    $('#delivery-line-items-modal').remove();
    $('body').append(xhr.response);

    $('#delivery-line-items-modal table').bootstrapTable({
      classes: 'table-no-bordered',
      pagination: true,
      pageSize: 20,
      pageList: [20, 50, 100],
      locale: 'zh-CN'
    });

    super.showModal('#delivery-line-items-modal');
  }
}
