import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  loadTemplate(event) {
    let id = $(event.target).val();
    let emailableType = $("#emailable_type").val();
    let emailableId = $("#emailable_id").val();
    $.ajax({
      url: "/erp/system/email_templates/" + id,
      data: { emailable_type: emailableType, emailable_id: emailableId },
      success: function(data) {
        $("#email_record_subject").val(data.subject);
        if(tinymce && tinymce.get('email_record_body')) {
          tinymce.get('email_record_body').setContent(data.body);
        } else {
          $("#email_record_body").val(data.body);
        }
      }
    })
  }

  closeModal(event) {
    let [response, status, xhr] = event.detail;
    if(xhr.status == 201) {
      super.hideModal('#new-email-record-modal');
      location.reload(true);
    } else {
      super.hideModal('#new-email-record-modal');
      $('#new-email-record-modal').replaceWith(xhr.response)
      super.showModal('#new-email-record-modal');
    }
  }
}
