import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  showFormModal(event) {
    let [response, status, xhr] = event.detail;
    super.setModalBody("#contact-form-modal", xhr.response);
    super.setModalTitle("#contact-form-modal", "新建联系人");

    super.showModal('#contact-form-modal');
  }
}
