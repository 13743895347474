import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ['customer']

  showShippingsModal(event) {
    let [response, status, xhr] = event.detail;

    if($("#shippings-modal").length > 0) {
      $("#shippings-modal").remove()
    }
    $('body').append(xhr.response);

    $('#shippings-modal table').bootstrapTable({
      classes: 'table-no-bordered',
      pagination: true,
      pageSize: 20,
      pageList: [20, 50, 100],
      locale: 'zh-CN'
    });

    super.showModal('#shippings-modal');
  }
}
