import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  showFormModal(event) {
    let [response, status, xhr] = event.detail;
    $("#pack-form-modal .modal-body").html(xhr.response);
    $("#pack-form-modal .modal-title").html("编辑包装")
    super.showModal('#pack-form-modal');
  }

  remove(event) {
    let [response, status, xhr] = event.detail;
    let errors = JSON.parse(xhr.response)["errors"];
    if(errors.length > 0) {
      $(errors).each(function(index, message) {
        alert(message)
      })
    } else {
      this.element.remove()
    }
  }
}
