import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  numberOfPackageChanged(event) {
    var number = parseInt($(event.target).val())
    if(isNaN(number)) {
          return
    }
    $(event.target).closest(".sortable-item").nextAll(".sortable-item").each(function(index, element) {
      $($(element).find("input")[0]).val(number + index + 1)
    });
  }

  showPackingListLineItemsModal(event) {
    let [response, status, xhr] = event.detail;
    $("body").append(xhr.response);

    $("#packing-list-line-items-modal table").bootstrapTable({
      classes: "table-no-bordered",
      pagination: true,
      pageSize: 20,
      pageList: [20, 50, 100],
      locale: "zh-CN"
    });

    super.showModal("#packing-list-line-items-modal");
  }
}
