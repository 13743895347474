import ApplicationController from "./application_controller"
import Dinero from "dinero.js"

export default class extends ApplicationController {
  static targets = ["amount", "exchangeRate", "localAmount"]

  initialize() {
    if(this.hasAmountTarget) {
      this.amount = this.amountTarget.value
    }
    if(this.hasExchangeRateTarget) {
      this.exchangeRate = this.exchangeRateTarget.value
    }
  }

  showNewEmailRecordModal(event) {
    let [response, status, xhr] = event.detail;
    $("body").append(xhr.response);

    super.showModal$("#new-email-record-modal");
  }

  showProductsModal(event) {
    let [response, status, xhr] = event.detail;

    $("#products-modal").remove()

    $("body").append(xhr.response);

    $("#products-modal table").bootstrapTable({
      classes: "table-no-bordered",
      pagination: true,
      pageSize: 20,
      pageList: [20, 50, 100],
      locale: "zh-CN",
      onLoadSuccess: function(data) {
        $.each(data.rows, function(index, value) {
          var cell = $("input[value=" + value.id + "]")[0];
          $("products-modal").find(cell).attr("name", "products[" + value.id + "][id]")
        });
      }
    });

    super.showModal('#products-modal');
  }

  showStatementLineItemsModal(event) {
    let [response, status, xhr] = event.detail;
    $("#statement-line-items-modal")
    $("body").append(xhr.response);

    $("#statement-line-items-modal table").bootstrapTable({
      classes: "table-no-bordered",
      pagination: true,
      pageSize: 20,
      pageList: [20, 50, 100],
      locale: "zh-CN"
    });

    super.showModal('#statement-line-items-modal');
  }

  showInvoiceLineItemsModal(event) {
    let [response, status, xhr] = event.detail;
    $("#invoice-line-items-modal").remove();
    $("body").append(xhr.response);

    $("#invoice-line-items-modal table").bootstrapTable({
      classes: "table-no-bordered",
      pagination: true,
      pageSize: 20,
      pageList: [20, 50, 100],
      locale: "zh-CN"
    });

    super.showModal('#invoice-line-items-modal');
  }

  showDeliveryLineItemsModal(event) {
    let [response, status, xhr] = event.detail;
    $("#delivery-line-items-modal").remove();
    $("body").append(xhr.response);

    $("#delivery-line-items-modal table").bootstrapTable({
      classes: "table-no-bordered",
      pagination: true,
      pageSize: 20,
      pageList: [20, 50, 100],
      locale: "zh-CN"
    });

    super.showModal('#delivery-line-items-modal');
  }

  selectCurrency(event) {
    self = this
    $.getJSON("/erp/accounting/exchange_rates/fetch", { currency_id: $(event.target).val() }, function(json) {
      if(null != json) {
        self.exchangeRate = json.rate
      } else {
        self.exchangeRate = 1.0
      }

      self.exchangeRateTarget.value = self.exchangeRate
      self.localAmountTarget.value = self.exchangeRate * self.amount
    });
  }

  calculate() {
    if(this.hasAmountTarget) {
      var value = Dinero({ amount: 0, precision: 3 });
      this.application.controllers.find(controller => {
        if(controller.context.identifier === "line-item") {
          value = value.add(controller.amount)
        };
      });
      this.amount = value.toFormat('0.000');
      this.amountTarget.value = this.amount.toFormat('0.000');
      this.localAmountTarget.value = this.amount.multiply(this.exchangeRate).toFormat('0.000');
    }
  }

  set amount(value) {
    this.data.set("amount", value);
  }

  get amount() {
    var value = parseFloat(this.data.get("amount")) * 1000 || 0;
    return Dinero({ amount: parseInt(value), precision: 3 });
  }

  set exchangeRate(value) {
    this.data.set("exchangeRate", value);
  }

  get exchangeRate() {
    return parseFloat(this.data.get("exchangeRate")) || 0;
  }
}
