import consumer from "channels/consumer"

consumer.subscriptions.create("NotificationsChannel", {
  connected() {
  },

  disconnected() {
  },

  received(data) {
    $(".toast-container").append(data)
  }
})
