import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  submit(event) {
    $(".loader").removeClass("is-active");

    let [response, status, xhr] = event.detail;
    $('.line-items tr.no-records-found').remove();
    $('.line-items').append(xhr.response);

    $.each($('.line-items').find('.sortable-item input.sortable-position'), function(index, item) {
      $(item).val(index + 1);
    });

    super.hideModal('#shippings-modal');
    super.initDateFields();
    super.initPopover();
  }
}
