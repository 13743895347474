import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static values = {
    id: Number
  }

  connect() {
    let self = this
    $.get("/erp/foreign/shippings/" + this.idValue + "/status", function(data) {
      $(self.element).replaceWith(data)
    })
  }
}
