import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  showFormModal(event) {
    let [response, status, xhr] = event.detail;
    super.setModalBody("#address-form-modal", xhr.response);
    super.setModalTitle("#address-form-modal", "新建地址");
    super.showModal('#address-form-modal');
  }
}
