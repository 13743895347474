import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  showNewEmailRecordModal(event) {
    let [response, status, xhr] = event.detail;
    $("#new-email-record-modal").remove();
    $("body").append(xhr.response);

    super.showModal('#new-email-record-modal');
    super.initRichTextArea();
  }
}
