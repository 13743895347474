import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  connect() {
    let dom = $(this.element).data("dom-id")
    let url = $(this.element).data("url")
    let contactableType = $(this.element).data("contactable-type")
    let select = $(this.element).find('select')

    $(dom).on("change", function() {
      let contactableId = $(dom).val();
      $.ajax({
        method: "GET",
        url: url,
        dataType: "json",
        data: { contactable_type: contactableType, contactable_id: contactableId }
      }).done(function(data) {
        select.find("option").remove();
        $.each(data, function(key, value) {
          select.append("<option value='" + value.id + "'>" + value.name + "</option>");
        });
      });
    });
  }
}
