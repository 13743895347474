import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  showFormModal(event) {
    let [response, status, xhr] = event.detail;
    super.setModalBody("#address-form-modal", xhr.response);
    super.setModalTitle("#address-form-modal", "编辑地址");
    super.showModal('#address-form-modal');
  }

  remove(event) {
    let [response, status, xhr] = event.detail;
    let errors = JSON.parse(xhr.response)["errors"];
    if(errors.length > 0) {
      $(errors).each(function(index, message) {
        alert(message)
      })
    } else {
      this.element.remove()
    }
  }
}
